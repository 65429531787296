module.exports = {
  siteTitle: 'Inspire Growth', // <title>
  siteDescription: 'where does site description appear',
  siteKeywords: 'inspire, growth, therapy, yoga, sustainability, minnesota, twin cities',
  manifestName: 'inspireGrowthManifest',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/inspire-growth-site`,
  heading: 'Heal first, then grow',
  subHeading: 'Science and wisdom-based therapies for a safe, peaceful, beautiful life',

  // social
  socialLinks: [
    {
      icon: 'fa-youtube',
      name: 'Youtube',
      url: 'https://youtube.com',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com',
    },
    {
      icon: 'fa-mastodon',
      name: 'Mastodon',
      url: 'https://mastodon.social',
    },
    // {
    //   icon: 'fa-tiktok',
    //   name: 'Tik Tok',
    //   url: 'https://tiktok.com',
    // },
  ],
  email: 'ashley@inspirecomn.com',
  phone: '612-361-7773',
  address: 'Twin Cites, MN',
};
