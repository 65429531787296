import React from 'react';

import config from '../../config';
import Layout from '../components/Layout';
import Header from '../components/Header';
import { Link } from 'gatsby';

const IndexPage = () => (
  <Layout>
    <Header />
    <header className="masthead">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Page not found</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">Not a valid URL</h2>

          <Link to="/" className="btn btn-primary">
            Go Home
          </Link>
        </div>
      </div>
    </header>
  </Layout>
);

export default IndexPage;

export const Head = ({ location, params, data, pageContext }) => (
  <>
    <title>{config.siteTitle}</title>
    <meta name="description" content={config.siteDescription} />
    <meta
      name="keywords" content={config.siteKeywords}
    />
  </>
)
